<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-end my-2">
          <template v-if="admins">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" text @click="dialogAdd"><v-icon small>mdi-plus</v-icon>
                  <v-icon small>mdi-account-network</v-icon></v-btn>
              </template>
              <span>Create Relationship</span>
            </v-tooltip>
          </template>
          <v-btn color="info" text @click="getRelations()">
            <v-icon small>mdi-reload</v-icon> Reload
          </v-btn>
        </div>



        <v-data-table :headers="headers" :items="relations" :loading="loading" class="elevation-1" item-key="id">
          <template v-slot:[`item.name`]="{ item }">
            {{ getName(item.employee) }}
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            {{ getPhone(item.employee) }}
          </template>
          <template v-slot:[`item.rol`]="{ item }">
            {{ getOccupation(item.ocupation) }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ prettyDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="error" @click="gotoDelete(item)" v-on="on" icon><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <span>Delete Relationship</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="addrelation" persistent :overlay="false" max-width="600px" transition="dialog-transition">
          <v-card>
            <v-toolbar dark color="primary"> Select Employee </v-toolbar>
            <v-card-text>
              <div class="text-h2 pt-4 px-4 d-flex">
                <v-select v-model="doctorUuid" label="Employees" :items="workers" prepend-inner-icon="mdi-doctor"
                  item-text="fullname" item-value="uuid" :loading="loading" hide-details clearable outlined dense />
                <v-select class="ml-1" :disabled="doctorUuid == '' || doctorUuid == null" v-model="ocupationId"
                  label="Occupations" :items="ocupations" item-text="name" item-value="id" :loading="loading"
                  hide-details outlined dense />
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelAdd"> Cancel </v-btn>
              <v-btn color="primary" :disabled="loading || doctorUuid == null || ocupationId == null" :loading="loading"
                @click="confirmAdd">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" persistent :overlay="false" max-width="500px" transition="dialog-transition">
          <v-card>
            <v-card-title class="red darken-1 white--text" primary-title>
              Delete Relationship:
              {{ CurrentRealiton ? CurrentRealiton.employee.fullname : "" }}
              {{
                CurrentRealiton && CurrentRealiton.ocupation
                  ? " - " + CurrentRealiton.ocupation.name
                  : ""
              }}
            </v-card-title>
            <v-card-text class="text-center mt-4">
              <h2>Are you sure?</h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelDelete()">Cancel</v-btn>
              <v-btn :disabled="loadingDelete" :loading="loadingDelete" text color="error"
                @click="confirmDelete()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import moment from "moment";
export default {
  name: "lead-relations",
  props: {
    lead: Object,
  },
  data() {
    return {
      addrelation: false,
      loading: false,
      ocupations: [],
      relations:[],
      doctorUuid: null,
      admins: false,
      ocupationId: null,
      loadingDelete: false,
      CurrentRealiton: null,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    headers() {
      return [
        { text: "Id", value: "id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Phone", value: "phone", sortable: false },
        { text: "Occupation", value: "rol", sortable: false },

        { text: "Creation Date", value: "createdAt", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },
   
  },
  watch: {
    doctorUuid(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.ocupationId = worker.ocupation ? worker.ocupation.id : null;
      } else {
        this.ocupationId = null;
      }
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    async getPatientData() {
      this.$emit("getData");
    },
    async getRelations() {
        this.loading = true;
       const uuidLead = this.$route.params.uuid;
        getAPI("social/relationsByLead/" + uuidLead)
        .then((res) => {
        this.relations = res.data;
        
        this.loading = false;
        })
        .catch((error) => {
        let mess = error.response.data.message.toString();
        this.loading = false;
        
        notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getName(item) {
      return item ? item.fullname : "-";
    },
    getPhone(item) {
      return item ? item.phone : "-";
    },
    getOccupation(item) {
      return item ? item.name : "-";
    },
    dialogAdd() {
      this.addrelation = true;
    },
    cancelAdd() {
      this.addrelation = false;
      this.loading = false;
      this.doctorUuid = null;
      this.ocupationId = null;
    },
    confirmAdd() {
      const p = this.$route.params.uuid;
      this.loading = true;
      getAPI
        .post("/patient/createRelation", {
          leadUuid: p,
          employeeUuid: this.doctorUuid,
          ocupationId: this.ocupationId,
        })
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientTransfer"));
          this.cancelAdd();
          this.getPatientData();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loading = false;
        });
    },

    gotoDelete(relation) {
      this.dialogDelete = true;
      this.CurrentRealiton = relation;
    },
    cancelDelete() {
      this.loadingDelete = false;
      this.dialogDelete = false;
      this.CurrentRealiton = null;
    },
    confirmDelete() {
      this.loadingDelete = true;

      getAPI
        .delete("/patient/deleteRelation/" + this.CurrentRealiton.id)
        .then(() => {
          notifyInfo(this.$t("Relationship has been deleted"));

          this.getPatientData();
          this.cancelDelete();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loadingDelete = false;
        });
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("MM-DD-YYYY");
    },

    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      if (this.workers.length == 0) {
        this.actGetEmployees();
      }
      this.admins = true;
    }
    this.getRelations();

    this.listOcupations();
  },
};
</script>
<style lang="scss" scoped>
.swipri {
  margin-top: -1px !important;
}
</style>
